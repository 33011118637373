import { getFooter } from "./footer";
import { getHeader } from "./navigation";

/**
 * Fetch the global nav and footer content.
 * @returns {Object} Axios response
 */
export const getWholeShell = async () => {
  const { 0: header, 1: footer } = await Promise.all([
    getHeader(),
    getFooter(),
  ]);


  return {
    header,
    footer,
  };
};

export default getWholeShell;
