/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LinkWithFallback } from "@marcel/react-component-library/es/atoms/Link";
import { LogoSwitcher } from "@marcel/react-component-library/es/molecules/LogoSwitcher";
import styles from "./navigation.scss";

/**
 * Navigation Markup
 * @param {Object} props component props
 * @param {string} props.className - className
 * @param {boolean} props.displayNav boolean
 * @param {Array} props.links header links
 * @param {string} props.logoLinkUrl logo redirection to home page
 * @param {boolean} props.isDataAccelerator flag for dataBootcamp header
 * @returns {React.Node} node JSX markup
 */
const Navigation = ({
  displayNav,
  links,
  logoLinkUrl,
  logo,
  className,
  autoid,
  isDataAccelerator,
}) => {
  const logoData = { ...logo, altText: "Marcel" };
  let daLogoData = { primaryUrl: "", altText: "" };
  let daSeparator = { primaryUrl: "", altText: "" };
  if (logo) {
    daLogoData = {
      ...logo,
      primaryUrl: logo?.daLogo?.[0]?.value,
      altText: logo?.altText || "DA Logo",
    };
    daSeparator = {
      ...logo,
      primaryUrl: logo?.daLogo?.[1]?.value,
      altText: logo?.altText || "DA Separator",
    };
  }
  /**
   * Replaces asset token
   *
   * @param {String} src object
   * @returns {String} src object with token replaced
   */

  return (
    <nav
      aria-label="Primary"
      className={clsx(
        styles.header,
        isDataAccelerator ? styles.daHeader : "",
        className,
        "region",
      )}
    >
      <LinkWithFallback
        href={logoLinkUrl}
        className={clsx(
          styles.logo,
          isDataAccelerator && styles.marcelLogo,
        )}
        data-autoid={`${autoid}_logo`}
      >
        <LogoSwitcher
          className={clsx({
            [styles.logoUrl]: logo && logo.primaryUrl,
            [styles.daDomainLogo]: isDataAccelerator,
          })}
          logo={logoData}
        />
      </LinkWithFallback>
      {isDataAccelerator && (
        <div className={styles.daContainer}>
          <LogoSwitcher
            className={clsx(
              {
                [styles.logoUrl]: logo && logo.primaryUrl,
              },
              styles.daSeparator,
            )}
            logo={daSeparator}
          />
          <LogoSwitcher
            className={clsx(
              {
                [styles.logoUrl]: logo && logo.primaryUrl,
              },
              styles.daLogo,
            )}
            logo={daLogoData}
          />
        </div>
      )}

      {displayNav && (
        <div>
          <ul className={styles.list}>
            {Array.isArray(links) &&
              links.map((link, idx) => {
                const linkAutoIdText =
                  link.iconKey ||
                  link.displayName ||
                  idx.toString() ||
                  "";
                const autoId = `${autoid}_${linkAutoIdText.toLowerCase()}`;

                return (
                  <li key={idx}>
                    <LinkWithFallback
                      href={link.link}
                      external={link.newTab}
                      data-autoid={autoId}
                    >
                      <div>{link.displayName}</div>
                    </LinkWithFallback>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </nav>
  );
};

Navigation.propTypes = {
  displayNav: PropTypes.bool,
  logoLinkUrl: PropTypes.string,
  logo: PropTypes.object,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      displayName: PropTypes.string,
      isExternal: PropTypes.bool,
      newTab: PropTypes.bool,
    }),
  ),
  autoid: PropTypes.string,
  isDataAccelerator: PropTypes.bool,
};

Navigation.defaultProps = {
  displayNav: true,
  logoLinkUrl: "/",
  links: [],
  autoid: "homepage",
  logo: {},
  isDataAccelerator: false,
};

export default Navigation;

/**
 * Wrapper for "plain" page layout usage
 * @param {object} logo logo parameter
 * @returns {React.Node} node
 */
export const PlainNavigation = () => (
  <Navigation logoLinkUrl="#" logo="" />
);
