// TODO: Will this need to be dynamic?
export const LOCALE = "en";

// For use in header/footer branding, etc.
export const SITE_NAME = "Marcel";

export const SITE_URL = "https://marcel.ai";

// Number to show at once.
export const ARTICLE_PAGE_SIZE = 10;

export const THIS_YEAR = new Date().getFullYear();

export const FONT_CSS_URL = "/app/assets/css/fonts.css";

export const MARCEL_AUTH_NAME = "MarcelAUTH";

export const EMAIL_SUBJECT = "Check out this article from Marcel";

export const EMAIL_SIGNATURE = "Sent from Marcel";

export const FOUR_LINE_BREAK = "%0D%0A%0D%0A%0D%0A%0D%0A";

export const SET_TRACKING = "SET_TRACKING";

export const ONETRUST_PERFORMANCE_GROUP = "C0002";

export const SPRINT_EPISODES = "episodes";

export const SPRINT_COURSES = "courses";

export const SPRINT_EXPERTS = "experts";

export const MAINTENANCE_FALLBACK_CONTENT = {
  title: "MAINTENANCE",
  message: "Marcel isn’t available right now. Try back again soon.",
  sorryMessage:
    "In order to perfect his circuitry, Marcel’s getting a bit of a tune-up at the moment. Thanks for your patience.",
};

export const DOWNTIME_INTERVAL_DURATION = 30000;

export const defaultContentErrorPage = {
  body:
    "<h5>My wires got crossed. I wasn't able to get you what you wanted. Give it another go.</h5>",
  heading: {
    emojiTitle: "Astonished face",
    emoji: "😵",
    text: "Oops! Something went wrong.",
  },
  eyebrow: "",
  metaTitle: "Error | Marcel",
  footer: {
    beginning: "",
    helpLink: "",
    helpText: "",
  },
  linksDescription: "",
  links: [
    {
      id: "CTA",
      text: "Go home",
      link: "/",
      name: "Go home",
    },
  ],
};
