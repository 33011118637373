import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import FooterLinks from "./footer-links";
import AppDownload from "./app-download";
import styles from "./footer.scss";

/**
 * The global footer
 * @param {Object} props components props
 * @param {Boolean} props.hasDownloads boolean for whether downloads section appears
 * @param {Array} props.links footer links to display
 * @param {Object} props.copy all copy text for
 * @param {boolean} props.isDataAccelerator flag for dataBootcamp footer
 * @returns {React.Node} node
 */
const Footer = ({
  isLite,
  hasDownloads,
  links,
  copy,
  className,
  isDataAccelerator,
}) => (
  <footer
    aria-label="Secondary"
    role="contentinfo"
    className={clsx(styles.footer, className)}
  >
    {hasDownloads && <AppDownload copy={copy} />}
    <FooterLinks
      className={clsx(
        !hasDownloads && styles.noDownloads,
        isLite && styles.litePage,
      )}
      copy={copy}
      isLite={isLite}
      links={links}
      isDataAccelerator={isDataAccelerator}
    />
  </footer>
);

Footer.propTypes = {
  isLite: PropTypes.bool,
  hasDownloads: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      openInNewWindow: PropTypes.bool,
    }),
  ),
  copy: PropTypes.shape({
    AppleLinkCopy: PropTypes.string,
    GoogleLinkCopy: PropTypes.string,
    DownloadHeaderCopy: PropTypes.string,
    copyrightCopy: PropTypes.string,
  }),
  isDataAccelerator: PropTypes.bool,
};

Footer.defaultProps = {
  isLite: false,
  hasDownloads: false,
  links: [],
  copy: {
    AppleLinkCopy: "App Store",
    GoogleLinkCopy: "Google Play",
    DownloadHeaderCopy: "Download the App",
    copyrightCopy: "© 2020 Publicis Groupe, all rights reserved",
  },
  isDataAccelerator: false,
};

export default Footer;

/**
 * Wrapper for "plain" page layout usage
 * @returns {React.Node} node
 */
export const PlainFooter = () => <Footer isLite />;
