import { getPublicContent } from ".";

/**
 * Fetch the static header
 */
export const getHeader = async () => {
  try {
    const data = await getPublicContent("/static/header");

    // clean up data
    data.primaryNavLinks = data.primaryNavLinks.filter(l => l);

    return data;
  } catch (err) {
    // Intentionally ignore errors and provide a default response
    return {};
  }
};

export default getHeader;
