import React from "react";
import PropTypes from "prop-types";
import Heading from "Components/Heading";
import { AppStoreIcon, GooglePlayIcon } from "Components/Icon";
import styles from "./footer.scss";

/**
 * App download links UI
 * @param {Object} props component props
 * @param {Object} props.copy copy passed to footer sections from CMS
 * @returns {React.Node} JSX markup for App Download section of footer
 */
const AppDownload = ({ copy }) => (
  <div className={styles.downloads}>
    <Heading className={styles.header} as="h3" kind="section">
      {copy.DownloadHeaderCopy}
    </Heading>
    {/* TODO: Add links to App Store pages */}
    <div className={styles.ctaBlock}>
      <div className={styles.cta}>
        <div className={styles.ctaIcon}>
          <AppStoreIcon className={styles.downloadIcon} />
        </div>
        <div className={styles.ctaText}>{copy.AppleLinkCopy}</div>
      </div>
      <div className={styles.cta}>
        <div className={styles.ctaIcon}>
          <GooglePlayIcon className={styles.downloadIcon} />
        </div>
        <div className={styles.ctaText}>{copy.GoogleLinkCopy}</div>
      </div>
    </div>
  </div>
);

AppDownload.propTypes = {
  copy: PropTypes.shape({
    AppleLinkCopy: PropTypes.string,
    GoogleLinkCopy: PropTypes.string,
    DownloadHeaderCopy: PropTypes.string,
  }),
};

AppDownload.defaultProps = {
  copy: {
    AppleLinkCopy: "Apple App Store",
    GoogleLinkCopy: "Google Play Store",
    DownloadHeaderCopy: "Download the App",
  },
};

export default AppDownload;
