import { useEffect } from "react";
import { string } from "prop-types";

/**
 * @param {String} src url of script
 */
const appendScriptToHead = src => {
  document.head.appendChild(
    Object.assign(document.createElement("script"), {
      async: true,
      src,
    }),
  );
};

/**
 * Create and insert a script in the <head> for tracking
 * @param {Object} props component props
 * @param {String} props.src the script source
 * @returns {React.Node} null node
 */
const TrackingScript = ({ src }) => {
  useEffect(() => {
    appendScriptToHead(src);
  }, []);
  return null;
};

TrackingScript.propTypes = {
  src: string.isRequired,
};

export default TrackingScript;
