/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Navigation from "Components/Navigation";
import Footer from "Components/Footer";
import styles from "./Layout.scss";
import ErrorPage from "../../pages/_error";

/**
 * Page layout w/ global navigation and footer
 * @param {Object} props component props
 * @param {Array} props.navLinks navigation links
 * @param {Array} props.footerItems footer links
 * @param {Array} props.footerCopy footer text content
 * @param {boolean} props.isDataAccelerator flag for dataBootcamp footer
 * @returns {React.Node} node
 */
const Layout = ({
  children,
  header,
  footerItems,
  footerCopy,
  isDataAccelerator,
}) => {
  const {
    primaryNavLinks: navLinks,
    homeLogoPath,
    homeLogoLayout,
    homeLink,
    labelSets,
  } = header || {};
  const logo = {
    primaryUrl: homeLogoPath,
    primaryLayout: homeLogoLayout,
    daLogo: labelSets,
  };

  return header && header.error ? (
    <ErrorPage
      statusCode={header.error.status}
      message={header.error.message}
    />
  ) : (
    <>
      <Navigation
        links={navLinks}
        logo={logo}
        logoLinkUrl={homeLink}
        isDataAccelerator={isDataAccelerator}
      />
      {children}
      <Footer
        className={styles.footer}
        links={footerItems}
        copy={footerCopy}
        isDataAccelerator={isDataAccelerator}
      />
    </>
  );
};

Layout.propTypes = {
  isDataAccelerator: PropTypes.bool,
};

Layout.defaultProps = {
  isDataAccelerator: false,
};
export default Layout;
