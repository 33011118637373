/**
 * This is all based off of:
 * https://github.com/lukashala/react-simply/tree/master/tools/state
 */
import React, { createContext, useContext, useReducer } from "react";
import { node, func, shape } from "prop-types";

export const StateContext = createContext();

/**
 * State context provider
 * @param {Object} props component props
 * @param {Function} props.reducer reducer
 * @param {Object} props.initialState initial state
 * @returns {React.Node} node
 */
export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

StateProvider.propTypes = {
  children: node.isRequired,
  initialState: shape({}).isRequired,
  reducer: func.isRequired,
};

/**
 * Hook shortcut for using state context in descendant components.
 * @returns {Array} [state, dispatch]
 */
export const useStateContext = () => useContext(StateContext);
