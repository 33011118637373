import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { LinkWithFallback } from "@marcel/react-component-library/es/atoms/Link";
import clsx from "clsx";
import PropTypes from "prop-types";
import { THIS_YEAR } from "../../constants";
import styles from "./footer.scss";
import { getSurpriseWishesStaticData } from '../../api/surpriseWishes';

/**
 * 
 * @returns {String} the copyright data
 */
const getWishesFooterCopy = () => {
  return getSurpriseWishesStaticData().then(response => response.labelSets.copyright);
}
/**
 * Application footer
 * @param {Object} props component props
 * @param {Array} props.links links to link to other pages on the site
 * @param {Object} props.copy copy for footer links and copyright disclaimer
 * @returns {React.Node} JSX markup for footer links and copyright section of footer
 */
const FooterLinks = ({
  isLite,
  className,
  links,
  copy,
  autoid,
  isDataAccelerator,
}) => {
  const [wishesFooterCopy, setWishesFooterCopy] = useState('');
  const router = useRouter();
  const hasWishes = router !== null && router.asPath.includes('wishes');

  useEffect(() => {
    /**
     * To get the footer copyright data
     */
    const fetchData = async () => {
      getWishesFooterCopy().then(footerCopy => setWishesFooterCopy(footerCopy));
    };

    fetchData();
  }, []);
  return (
  <div
    className={clsx(
      styles.container,
      className,
      isDataAccelerator && styles.daContainer,
    )}
  >
    {!isLite && (
      <div className={styles.footerContent}>
        <ul className={styles.list}>
          {Array.isArray(links) &&
            links.map(
              (link, idx) =>
                ((isDataAccelerator &&
                  link?.displayName?.includes("data-accelerator")) ||
                  (isDataAccelerator === false &&
                    !link?.displayName?.includes(
                      "data-accelerator",
                    ))) && (
                  <li key={idx}>
                    <LinkWithFallback
                      className={clsx(styles.link, {
                        [styles.daLink]: isDataAccelerator,
                      })}
                      external={link.newTab}
                      href={link.link}
                      data-autoid={`${autoid}_${(
                        link.label ||
                        link.displayName ||
                        ""
                      ).toLowerCase() || idx}`}
                    >
                      {link.label}
                    </LinkWithFallback>
                  </li>
                ),
            )}
        </ul>
      </div>
    )}
    <div
      className={clsx(styles.footerContent, {
        [styles.daFooterContent]: isDataAccelerator,
      })}
      data-autoid={`${autoid}_copyright`}
    >
      {hasWishes ? wishesFooterCopy : copy.copyrightCopy}
    </div>
  </div>
  )
};

FooterLinks.propTypes = {
  isLite: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      openInNewWindow: PropTypes.bool,
    }),
  ),
  copy: PropTypes.shape({
    copyrightCopy: PropTypes.string,
  }),
  autoid: PropTypes.string,
  isDataAccelerator: PropTypes.bool,
};

FooterLinks.defaultProps = {
  isLite: false,
  links: [],
  copy: {
    copyrightCopy: `© ${THIS_YEAR} Publicis Groupe, all rights reserved`,
  },
  autoid: "homepage_footer",
  isDataAccelerator: false,
};

export default FooterLinks;
