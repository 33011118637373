/* eslint react-hooks/rules-of-hooks: "error", react-hooks/exhaustive-deps: "error" */
import React, { useEffect } from "react";
import { trackEvent } from "@marcel/utils";
import { node } from "prop-types";
import { ONETRUST_PERFORMANCE_GROUP, SET_TRACKING } from "constants";
import getConfig from "next/config";

import TrackingScript from "../TrackingScript";
import { useStateContext } from "../State";
import useOneTrust from "../../hooks/useOneTrust";

const {
  GOOGLE_TAG_MANAGER_ID,
  ADOBE_DTM_PATH,
  ONETRUST_ENABLED,
} = getConfig().publicRuntimeConfig;

/**
 * @param {Object} props component props
 * @param {React.Node} children react children nodes
 * @returns {React.Node} node
 */
const Tracking = ({ children }) => {
  const dispatch = useStateContext()[1];
  const [shouldTrack] = useOneTrust(ONETRUST_ENABLED, payload => {
    dispatch({ type: SET_TRACKING, payload });
  });
  useEffect(() => {
    if (shouldTrack(ONETRUST_PERFORMANCE_GROUP)) {
      trackEvent({
        event: "gtm.js",
        "gtm.start": new Date().getTime(),
      });
    }
  }, [shouldTrack]);

  return (
    <>
      {children}
      {GOOGLE_TAG_MANAGER_ID &&
        shouldTrack(ONETRUST_PERFORMANCE_GROUP) && (
          <TrackingScript
            src={`https://www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_ID}`}
          />
        )}
      {ADOBE_DTM_PATH && shouldTrack(ONETRUST_PERFORMANCE_GROUP) && (
        <TrackingScript src={ADOBE_DTM_PATH} />
      )}
    </>
  );
};

Tracking.propTypes = {
  children: node.isRequired,
};

export default Tracking;
