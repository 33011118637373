import { getPublicContent } from ".";

/**
 * Fetch footer content
 */
export const getFooter = async () => {
  try {
    const footer = await getPublicContent("/static/footer");
    return footer;
  } catch (err) {
    // Intentionally ignore errors and provide a default response
    return {};
  }
};

export default getFooter;
