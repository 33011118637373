import { handleError, getPublicContent, getUserContent } from ".";

/**
 * Fetch surprise wishes page content
 * @returns {Object} data
 */
export const getSurpriseWishesStaticData = async () => {
  try {
    const data = await getPublicContent("/wishes/wishes");
    return data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Fetch surprise wishes dynamic video
 * @param {String} userKey the key of the user
 * @returns {Object} data
 */
export const getSurpriseWishesVideoLink = async userKey => {
  const hashedLLID = userKey;
  try {
    const apiEndpoint = `/api/v3/people/wishes?hashedLLID=${hashedLLID}`;
    const data = await getUserContent(apiEndpoint);
    return data;
  } catch (error) {
    return handleError(error);
  }
};
