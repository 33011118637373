import "@marcel/styleguide/dist/global-styles.css";
import "./_app.scss";

import React from "react";
import App from "next/app";
import { ONETRUST_PERFORMANCE_GROUP, SET_TRACKING } from "constants";

import { getOr } from "utils";
import DefaultLayout from "Components/Layout";
import { StateProvider } from "Components/State";
import Tracking from "Components/Tracking";
import { trackVideoEvent } from "@marcel/utils";
import getShell from "api/shell";
import NextHead from "next/head";
import { FONT_CSS_URL } from "constants/index";

/**
 * Global app state
 */
const initialState = {
  shouldTrack: () => true,
};

/**
 * Reducer
 * TODO: Consider adding some API for middleware
 * https://medium.com/maxime-heckel/rebuilding-redux-with-hooks-and-context-e16b59faf51c
 * https://github.com/shiningjason/react-enhanced-reducer-hook
 * @param {Object} oldState current state
 * @param {Object} action action
 * @param {String} action.type action type
 * @returns {Object} new state
 */
const reducer = (oldState, { type, payload }) => {
  let state = { ...oldState };
  let { shouldTrack } = state;

  switch (type) {
    case "VIDEO_PLAY":
    case "VIDEO_PAUSE": {
      // Parse tracking event action from reducer action
      const action = type.split("_")[1].toLowerCase();
      if (shouldTrack(ONETRUST_PERFORMANCE_GROUP)) {
        trackVideoEvent({ action, title: payload.title });
      }
      break;
    }
    case "VIDEO_MILESTONE": {
      if (shouldTrack(ONETRUST_PERFORMANCE_GROUP)) {
        trackVideoEvent({
          action: `${payload.milestone * 100}% milestone`,
          title: payload.title,
        });
      }
      break;
    }

    case SET_TRACKING: {
      shouldTrack = payload;
      state = {
        shouldTrack,
        ...state,
      };
      break;
    }
    default:
      break;
  }

  return state;
};

/**
 * @class MarcelApp
 */
export default class MarcelApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const { footer, header } = await getShell();
    let isDataAccelerator = false;
    if (ctx.pathname.includes("/publiciscurated")) {
      isDataAccelerator = true;
    }

    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};
    const copyArray = getOr([], "labelSets", footer);
    const copyLabelObj = !isDataAccelerator
      ? copyArray.find(lbl => lbl.label === "copyrightCopy")
      : copyArray.find(lbl => lbl.label.includes("data-accelerator"));
    const copyText = copyLabelObj && copyLabelObj.value;
    const copyProp = copyText ? { copyrightCopy: copyText } : undefined;

    return {
      pageProps,
      // TODO: find a way to avoid these deep props
      header,
      // set footer data defaults in case these structures do not exist
      footerItems: getOr([], "footerLinks", footer),
      footerCopy: copyProp,
      isDataAccelerator,
    };
  }

  // TODO: This should be done with an action instead?

  componentDidMount() {
    // log our commithash, version, & branch (from git-revision-webpack-plugin)
    // eslint-disable-next-line no-console
    console.log(
      `VERSION ${VERSION}, COMMITHASH ${COMMITHASH}, BRANCH ${BRANCH}`, // eslint-disable-line no-undef
    );
  }

  render() {
    const {
      Component,
      pageProps,
      header,
      footerItems,
      footerCopy,
      isDataAccelerator,
    } = this.props;
    // Look up the layout component to use
    const Layout = DefaultLayout;
    return (
      <StateProvider initialState={initialState} reducer={reducer}>
        {FONT_CSS_URL && (
          <NextHead>
            <link
              rel="stylesheet"
              type="text/css"
              href={FONT_CSS_URL}
            />
          </NextHead>
        )}
        <Tracking>
          <Layout
            header={header}
            footerItems={footerItems}
            footerCopy={footerCopy}
            isDataAccelerator={isDataAccelerator}
          >
            <main>
              <Component {...pageProps} />
            </main>
          </Layout>
        </Tracking>
      </StateProvider>
    );
  }
}
