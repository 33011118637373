/* eslint react-hooks/rules-of-hooks: "error", react-hooks/exhaustive-deps: "error" */
import { useEffect, useState, useCallback } from "react";

const isClient = typeof window === "object";

/**
 * @param {String} oneTrustEnabled is onetrust running
 * @param {function} callback callback function to when onetrust details have changed
 * @returns {array} shouldTrack function and active groups user belongs to
 */
const useOneTrust = (oneTrustEnabled, callback = null) => {
  const [activeGroups, setActiveGroups] = useState(
    isClient && oneTrustEnabled && window.OnetrustActiveGroups
      ? window.OnetrustActiveGroups.split(",").filter(x => x)
      : [],
  );

  const shouldTrack = useCallback(
    (groupId = null) => {
      if (!isClient) return true;

      if (oneTrustEnabled) {
        // if no groupId is passed this is considered essential tracking
        if (!groupId) {
          return true;
        }
        // else make sure the user has opted into this group
        return activeGroups.indexOf(groupId) >= 0;
      }
      return true;
    },
    [activeGroups, oneTrustEnabled],
  );

  const onOneTrustChanged = useCallback(() => {
    const newActiveGroups = window.OnetrustActiveGroups.split(
      ",",
    ).filter(x => x);

    // if the cookie preferences are less than before
    // it means we have to reload the pages to unload tag managers
    if (newActiveGroups.length < activeGroups.length) {
      window.location.reload();
    }

    setActiveGroups(newActiveGroups);
    if (callback) {
      callback(shouldTrack);
    }
  }, [activeGroups.length, callback, shouldTrack]);

  useEffect(() => {
    let ret;
    if (isClient) {
      if (oneTrustEnabled) {
        window.addEventListener("consent.onetrust", onOneTrustChanged);
        ret = () => {
          window.removeEventListener(
            "consent.onetrust",
            onOneTrustChanged,
          );
        };
      }
    }
    return ret;
  }, [oneTrustEnabled, callback, onOneTrustChanged]);

  return [shouldTrack, activeGroups];
};

export default useOneTrust;
